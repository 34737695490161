<script>
import Layout from "../../../../layouts/main";
import userForm from "./components/Userform";

import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Nouvel Utilisateur",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, userForm },
  watch: {
    
  },
  data() {
    return {
      title: "Nouvel Utilisateur",
      suppliers: [],
      users: [],
      directions: [],
      divisions: [],
      departments: [],
      services: [],
      roles: [],
      userForm: {
        firstName: "",
        lastName: "",
        email: "",
        username: "",
        supplier: "",
        type: "",
        directSuperior: "",
        role: "",
        structureType: "",
        direction: "",
        division: "",
        department: "",
        service: "",
        password: "",
        id_number: "",
      },
      userStructuresTypes: [],
      types: [
        {
          id: "employee",
          name: "Employé SH",
        },

        {
          id: "supplier_staff",
          name: "Employé Prestataire",
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      items: [
        {
          text: "Paramètres",
          href: "/",
        },
        {
          text: "Nouvel Utilisateur",
          active: true,
        },
      ],
    };
  },


 
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <user-form/>
    <!-- end row -->
  </Layout>
</template>
